
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import EditRole from './edit-role.vue'
import PeopleFind from '../../../components/People/people-find.vue'

class PageRoleRequest extends PageRequest {
  keyword: string = ''
}

@Component({
  components: { EditRole, PeopleFind }
})
export default class Roles extends AbpBase {
  pagerequest: PageRoleRequest = new PageRoleRequest()

  createModalShow: boolean = false
  editModalShow: boolean = false
  findModalShow: boolean = false

  searchDefinition: {}[] = [
    {
      fieldName: 'Busqueda',
      fieldType: 'text',
      fieldBind: 'keyword',
      value: ''
    }
  ]

  pagination: any = {}

  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value
      } else {
        delete this.pagerequest[element.fieldBind]
      }
    })
    await this.getpage()
    this.findModalShow = false
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit('role/setCurrentPage', pagination.current)
    this.getpage()

    await this.getpage()
  }
  get list() {
    return this.$store.state.role.list
  }
  get loading() {
    return this.$store.state.role.loading
  }
  editRow(item, index, button) {
    this.$store.commit('role/edit', item)
    this.edit()
  }
  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L('¿Desea eliminar este rol?'), {
        //title: this.L('Tips'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.L('Sí'),
        cancelTitle: this.L('No'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: 'role/delete',
            data: item
          })
          await this.getpage()
        }
      })
  }
  find() {
    this.findModalShow = true
  }

  hide() {
    this.findModalShow = false
  }
  edit() {
    this.editModalShow = true
  }
  create() {
    this.createModalShow = true
  }
  pageChange(page: number) {
    this.$store.commit('role/setCurrentPage', page)
    this.getpage()
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit('role/setPageSize', pagesize)
    this.getpage()
  }
  async getpage() {
    this.pagerequest.maxResultCount = this.pageSize
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize

    await this.$store.dispatch({
      type: 'role/getAll',
      data: this.pagerequest
    })
  }
  get pageSize() {
    return this.$store.state.role.pageSize
  }
  get totalCount() {
    return this.$store.state.role.totalCount
  }
  get currentPage() {
    return this.$store.state.role.currentPage
  }
  set currentPage(page) {
    this.$store.commit('role/setCurrentPage', page)
    this.getpage()
  }
  columns = [
    {
      title: this.L('Rol'),
      dataIndex: 'normalizedName',
      scopedSlots: { customRender: 'status' }
    },
    {
      title: this.L('Permisos'),
      dataIndex: 'grantedPermissions',
      scopedSlots: { customRender: 'perms' }
    },
    {
      title: this.L('Nombre '),
      dataIndex: 'displayName'
    },
    {
      title: this.L('Descripcion'),
      dataIndex: 'description'
    },
    {
      scopedSlots: { customRender: 'editBtn' },
      width: 10,
      title: '',
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    },
    {
      scopedSlots: { customRender: 'removeBtn' },
      width: 10,
      title: '',
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    }
  ]
  async created() {
    this.getpage()
    await this.$store.dispatch({
      type: 'role/getAllPermissions'
    })
    await this.$store.dispatch({
      type: 'role/getAllPermissionsTree'
    })
  }
}
