
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Role from '@/store/entities/role'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import Treeselect from '@riophae/vue-treeselect'
@Component({
  components: { Modal, BaseButton, BaseCheckbox, Treeselect }
})
export default class EditRole extends AbpBase {
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: Boolean, default: false }) editing: boolean
  @Prop({ type: String }) id: string
  role: Role = new Role()
  title: String = ''
  rolesTree: any = null
  disabled: boolean = false
  get permissions() {
    return this.$store.state.role.permissions
  }
  get permissionsTree() {
    return this.$store.state.role.permissionsTree
  }

  created() {}
  async save() {
    if (this.checkFormValidity()) {
      if (this.editing) {
        await this.$store.dispatch({
          type: 'role/update',
          data: this.role
        })
      } else {
        await this.$store.dispatch({
          type: 'role/create',
          data: this.role
        })
      }

      this.$emit('save-success')
      this.$emit('input', false)
      this.resetFields()
    }
  }
  close() {
    this.$emit('input', false)
    this.resetFields()
  }
  shown() {
    if (this.editing) {
      this.role = Util.extend(true, {}, this.$store.state.role.editRole)
      this.title = this.L('Editar rol')
    }
    else {
      this.title = this.L('Crear nuevo rol')
    }
    this.setDisabled()
  }
  checkFormValidity() {
    const valid = (this.$refs.roleForm as any).checkValidity()
    return valid
  }
  resetFields() {
    this.role = new Role()
  }

  setDisabled() {
    this.disabled = !abp.auth.isAnyGranted('OZONE.Roles.Edit', 'OZONE.Roles.Create', 'OZONE.Roles.Delete')
  }

  roleRule = {
    name: {
      required: { required: true, min: 2, max: 32 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('RoleName')),
      placeholder: this.L('Nombre'),
      trigger: 'blur'
    },
    displayName: {
      required: { required: true, min: 3 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('DisplayName')),
      placeholder: this.L('Nombre a mostrar'),
      trigger: 'blur'
    },
    description: {
      required: { required: false, min: 3 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Description')),
      placeholder: this.L('Descripción'),
      trigger: 'blur'
    },
    permisos: {
      placeholder: this.L('Seleccione permisos')
    }
  }
}
